.ac-mainbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: url("../assets/img/Portfolio/PortfolioBox22.svg") no-repeat center center;
    background-size: cover;
    font-size: 12px;
    margin-top: 30px;
}

.ac-mainbox-top {
    display: flex;
    align-items: flex-end;
    justify-content: left;
}

.ac-mainbox-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ac-span1 {
    font-weight: 500;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;
}

.ac-spanbold {
    font-weight: 500;
}