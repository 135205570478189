.t-mainbox-emptytrash-image {
   background: url("../assets/img/Misc/EmptyTrashButton.svg") no-repeat center center;
   background-size: cover;
   margin-bottom: 10px;
   margin-right: 20px;
   cursor: pointer;
}

.t-smallmedialist {
   height: 400px;
   display: flex;

   flex-wrap: wrap;
}

.t-image-restore {
   background: url("../assets/img/IconsAndButtonTemplates/RestoreIndividualTrashButton.svg") no-repeat center center;
   background-size: cover;
   cursor: pointer;
   z-index: 100;
}

.t-image-delete {
   background: url("../assets/img/IconsAndButtonTemplates/DeleteIndividualTrashButton.svg") no-repeat center center;
   background-size: cover;
   cursor: pointer;
}

.t-image-battons {
   display: none;
   position: relative;
}

.t-smallmediadiv1 {}

.t-smallmediadiv {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 90px;
   width: 140px;
   /*border: 1px solid violet; */
}

/*.t-smallmediadiv1:hover .t-image-battons {display: flex;}*/
/*********Empty Trash message***************/
.t-emptytrashbox {
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   flex-direction: column;
   background: url("../assets/img/SignupAndLoginPages/DialogueBox1.svg") no-repeat center center;
   background-size: cover;
   z-index: 100;

}

.t-emptytraahdbox-title {
   display: flex;
   align-items: center;
   justify-content: center;
   font-weight: 400;
   font-family: 'Oswald', sans-serif;
   letter-spacing: 1px;
   font-family: 'Oswald', sans-serif;

}

.t-emptytraahdbox-text {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   font-weight: 300;
   font-family: 'Oswald', sans-serif;
   letter-spacing: 2px;

}

.t-emptytraahdbox-buttons {
   display: flex;
   align-items: center;
   justify-content: center;
}

.t-emptytraahdbox-empty {
   display: flex;
   align-items: center;
   justify-content: center;
   font-weight: 200;
   color: white;
   letter-spacing: 1px;
   background: url("../assets/img/IconsAndButtonTemplates/ButtonRedWithWhiteTextSmall.svg") no-repeat center center;
   background-size: cover;
   cursor: pointer;
}

.t-emptytraahdbox-cancel {
   display: flex;
   align-items: center;
   justify-content: center;
   font-weight: 300;
   letter-spacing: 1px;
   background: url("../assets/img/IconsAndButtonTemplates/ButtonGreyWithDarkTextSmall.svg") no-repeat center center;
   background-size: cover;
   cursor: pointer;
}

/*********End of Empty Trash message***************/