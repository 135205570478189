.bg_div_mob {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    background: linear-gradient(rgb(100, 100, 106), rgb(200, 200, 200));
    width: 100%;
}

.title_div_mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    margin-top: 15px;
}

.main_div_mob {
    /*border: 1px solid blue;*/
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: #a6abb9;
    min-height: 85vh;
    border-radius: 12px;
    margin-bottom: 15px;
}

.report-span-mob {
    font-size: 15px;
    font-weight: 200;
    font-family: 'Oswald', sans-serif;
}