.r-mainbox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url("../assets/img/Portfolio/PortfolioBox1.svg") no-repeat center center;
    background-size: cover;
    font-size: 12px;
    margin-top: 80px;

}

.r-mainbox-top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.r-span1 {
    font-weight: 500;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;
    margin-bottom: 8px;
}

.r-mainbox-sort {
    display: flex;


}

.r-mainbox-sort-left {
    display: flex;
}

.r-mainbox-sort-image {
    background: url("../assets/img/Portfolio/SortButton.svg") no-repeat center center;
    background-size: cover;
    margin-left: 20px;
    margin-right: 23px;
    cursor: pointer;
}

.r-mainbox-search {
    display: flex;
    justify-content: right;
    background: url("../assets/img/Portfolio/SearchBar.svg") no-repeat center center;
    background-size: cover;
}

.r-mainbox-search input[type="text"] {
    font-size: 20px;
    text-align: left;
    border: 0px;
    border-radius: 7px;
    background: rgba(0, 0, 0, 0);
    margin-right: 10px;
    outline: none;
}

.r-mainbox-media {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.r-mainbox-bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

}

/*  from   prev **************************/
.myreports-content-item {
    display: flex;
    width: 1200px;
    border: 1px solid black;
    margin-bottom: 10px;
    margin-left: 4px;

}

.myreports-content-item-left {
    position: relative;
    top: 0;
    left: 0;

    width: 110px;
}

.myreports-content-item-image {
    position: relative;

    max-width: 110px;
    top: 0;
    left: 0;
    padding: 0px;
}

.myreports-content-item-right {
    display: flex;
    flex-direction: column;
    width: 1290px;
}

.myreports-content-item-header {
    display: flex;
    justify-content: space-between;
    width: 1290px;
    /*border: 1px solid blue; */
}

/*, border: '1px solid blue' */
.myreports-content-item-header-text {
    width: 425px;
    padding: 10px;
    font-weight: 700;
    font-size: 13px;
    /*border: 1px solid red;*/
}

.myreportcontentmenu {
    display: flex;
    justify-content: space-between;

}

.myreportmenu-item {
    width: 55px;
    max-height: 27px;
    text-align: center;
    font-size: 15px;
    font-weight: 800;
    margin: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.myreports-content-item-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1100px;

}

/* Table*/
.myreportstable {
    /*border-collapse:collapse;*/
    border-spacing: 0;
    font-weight: 500;
}

.tg-0lax {
    text-align: center;
    vertical-align: top;
}

.td1 {
    width: 120px;
}

.td2 {
    width: 610px;
}

.td3 {
    width: 120px;
}

.td4 {
    width: 120px;
}

.td5 {
    width: 120px;
}

.td6 {
    width: 210px;
}