.bigmsg-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: url("../assets/img/SignupAndLoginPages/BigMsg.svg") no-repeat center center;
    background-size: cover;
}

.bigmsg-box-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #494b53;
}

.bigmsg-box-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}

.bigmsg-box-middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.bigmsg-box-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.bigmsg-box-buttons-mob {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}


.bigmsg-input-div {
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;

}