.pg-mainbox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 787px;
    width: 1420px;
    background: url("../assets/img/Portfolio/PortfolioBox22.svg") no-repeat center center;
    background-size: cover;
    font-size: 12px;
    margin: 8px;

}

.pg-mainbox-top {
    display: flex;
    align-items: flex-end;
    justify-content: left;

}

.pg-span1 {
    font-weight: 500;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;
    margin-bottom: 8px;
}

.pg-mainbox-sort {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.pg-mainbox-sort-left {
    display: flex;
}

.pg-mainbox-sort-image {
    background: url("../assets/img/Portfolio/SortButton.svg") no-repeat center center;
    background-size: cover;
    margin-left: 20px;
    margin-right: 23px;
    cursor: pointer;
}

.pg-mainbox-search {
    display: flex;
    justify-content: right;
    background: url("../assets/img/Portfolio/SearchBar.svg") no-repeat center center;
    background-size: cover;
}

.pg-mainbox-search input[type="text"] {
    font-size: 20px;
    text-align: left;
    border: 0px;
    border-radius: 7px;
    background: rgba(0, 0, 0, 0);
    margin-right: 10px;
    outline: none;
}

.pg-mainbox-media {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.pg-mainbox-media-about {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.pg-terms-mainbox-bottom {
    display: flex;
    align-items: flex-start;
    justify-content: end;
    cursor: pointer;
    z-index: 101;
}

.pg-terms-mainbox-bottom-mob {
    display: flex;

    flex-direction: column;
    cursor: pointer;
    z-index: 101;
}

/*********** About ********************/

.pg-about-link {
    color: 'blue';
    font-weight: '400';
    font-size: '15px';
    margin-left: 30px;

}


/*********** END OF About *************/
/*********** Settings ********************/
.image-addurl {
    background: url("../assets/img/IconsAndButtonTemplates/SmallGreenAdd.svg") no-repeat center center;
    background-size: cover;
    cursor: pointer;
    z-index: 100;
}


/*********** END OF Settings *************/