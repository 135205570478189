body,
html {
    overflow: hidden;
}

.p-mainbox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /*height: 787px;
    width: 1420px;*/
    /*max-width: 1420px;    */
    background: url("../assets/img/Portfolio/PortfolioBox22.svg") no-repeat center center;
    background-size: cover;
    font-size: 12px;
    margin-top: 0px;
    margin-left: 5px;
    margin-right: 5px;
}

.p-mainbox-top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.p-mainbox-sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.p-mainbox-sort-menu-mostalerts {
    background: url("../assets/img/Portfolio/SortOptionWindow/MostAlerts.svg") no-repeat center center;
    background-size: cover;
}

.p-mainbox-sort-menu-newest {
    background: url("../assets/img/Portfolio/SortOptionWindow/DateAddedNewest.svg") no-repeat center center;
    background-size: cover;
}

.p-mainbox-sort-menu-oldest {
    background: url("../assets/img/Portfolio/SortOptionWindow/DateAddedOldest.svg") no-repeat center center;
    background-size: cover;
}

.p-mainbox-sort-menu-az {
    background: url("../assets/img/Portfolio/SortOptionWindow/Alphabetical.svg") no-repeat center center;
    background-size: cover;
}

.p-mainbox-sort-menu-item {
    margin: 1px;
    cursor: pointer;
    z-index: 102;
}

.p-mainbox-sort-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.p-mainbox-sort-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    background: url("../assets/img/Portfolio/SortOptionWindow/Box.svg") no-repeat center center;
    background-size: cover;
    z-index: 102;
}

.p-mainbox-media {
    display: flex;
    align-items: center;
    justify-content: center;
    /*border: 1px solid yellow; */
}

.p-mainbox-scroll {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-mainbox-bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.p-add {
    display: flex;
    background: url("../assets/img/Portfolio/AddImageButton.svg") no-repeat center center;
    background-size: cover;
    margin-bottom: 10px;
    margin-right: 0px;
    cursor: pointer;
}

.p-span1 {
    font-weight: 500;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;
    margin-bottom: 8px;
}

.p-mainbox-sort-image {
    position: relative;
    background: url("../assets/img/Portfolio/SortButton.svg") no-repeat center center;
    background-size: cover;
    cursor: pointer;
}

.p-mainbox-sort-left {
    display: flex;
}

.p-mainbox-sort-right1 {
    display: block;
    background: url("../assets/img/Portfolio/PortfolioToggleView1.svg") no-repeat center center;
    background-size: cover;
    margin-right: 24px;
    margin-bottom: 4px;
    cursor: pointer;
}

.p-mainbox-sort-right2 {
    display: none;
    background: url("../assets/img/Portfolio/PortfolioToggleView2.svg") no-repeat center center;
    background-size: cover;
    margin-right: 24px;
    margin-bottom: 4px;
    cursor: pointer;
}

.p-mainbox-sort-right1-mob {
    display: block;
    background: url("../assets/img/Portfolio/Portrait/GridViewButton.svg") no-repeat center center;
    background-size: cover;
    cursor: pointer;
}

.p-mainbox-bydemand-mob {
    display: flex;
    background: url("../assets/img/Portfolio/Portrait/MobileOnDemandSearchButton.svg") no-repeat center center;
    background-size: cover;
    cursor: pointer;
}


.p-mainbox-sort-right2-mob {
    display: none;
    background: url("../assets/img/Portfolio/Portrait/ListViewButton.svg") no-repeat center center;
    background-size: cover;
    cursor: pointer;
}


.p-mainbox-search {
    display: flex;
    justify-content: right;
    background: url("../assets/img/Portfolio/SearchBar.svg") no-repeat center center;
    background-size: cover;

}

.p-mainbox-search input[type="text"] {
    font-size: 20px;
    text-align: left;
    border: 0px;
    border-radius: 7px;
    background: rgba(0, 0, 0, 0);
    margin-right: 10px;
    outline: none;
}

.p-mainbox-bottom-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #a6abb9;

    letter-spacing: 1px;

}

.p-mainbox-bottom-right1 {
    background: url("../assets/img/Portfolio/TrashButtonFull.svg") no-repeat center center;
    background-size: cover;
    margin-top: 6px;
    margin-right: 20px;
    cursor: pointer;
}

.p-mainbox-bottom-right2 {
    background: url("../assets/img/Portfolio/TrashButtonEmpty.svg") no-repeat center center;
    background-size: cover;
    margin-right: 20px;
    cursor: pointer;
}

.p-mainbox-bottom-right1-mob {
    background: url("../assets/img/Portfolio/Portrait/TrashButtonFull.svg") no-repeat center center;
    background-size: cover;
    margin-right: 20px;
    cursor: pointer;
}

.p-mainbox-bottom-right2-mob {
    background: url("../assets/img/Portfolio/Portrait/TrashButtonEmpty.svg") no-repeat center center;
    background-size: cover;
    margin-right: 20px;
    cursor: pointer;
}




.p-mainbox-bottom-totalalerts {
    background: url("../assets/img/Portfolio/InfoBoxTotalAlerts.svg") no-repeat center center;
    background-size: cover;
    margin-left: 0px;
    text-align: right;
}

.p-mainbox-bottom-capacity {
    background: url("../assets/img/Portfolio/InfoBoxPortfolioCapacity.svg") no-repeat center center;
    background-size: cover;
    margin-left: 10px;
}

.p-mainbox-bottom-fr {
    background: url("../assets/img/Portfolio/InfoBoxScanFrequency.svg") no-repeat center center;
    background-size: cover;
    margin-left: 10px;
}

.p-mainbox-bottom-notifications {
    background: url("../assets/img/Portfolio/InfoBoxNotificationPreference.svg") no-repeat center center;
    background-size: cover;
    margin-left: 10px;
}

.p-mainbox-bottom-users {
    background: url("../assets/img/Portfolio/InfoBoxNumberOfUsers.svg") no-repeat center center;
    background-size: cover;
    margin-left: 10px;
}

.p-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
}

.largemedialist {
    display: flex;
    flex-wrap: nowrap;
}

.smallmedialist {
    height: 400px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.smallmediadiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    width: 140px;
    /*border: 1px solid violet; */
}

.smallmediadiv1 {}

.largemediadiv {}

.largemediaimage {
    height: 380px;
    padding: 10px;

}

.smallmediaimage {
    max-height: 82px;
    max-width: 132px;
    padding: 0px;
}

.p-image-notakn-mob {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../assets/img/Portfolio/ImageAlertBadge.svg") no-repeat center center;
    background-size: cover;
    color: white;
}

.p-image-notakn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -425px;
    left: 20px;
    background: url("../assets/img/Portfolio/ImageAlertBadge.svg") no-repeat center center;
    background-size: cover;
    color: white;
}

.p-image-delete {
    display: none;
    position: relative;
    background: url("../assets/img/Portfolio/ImageDeleteButton.svg") no-repeat center center;
    background-size: cover;
    cursor: pointer;
}

.p-single-image-search {
    display: none;
    align-items: center;
    justify-content: center;
    background-color: #494b53;
    border-radius: 30px;
    color: #e6e6e6;
    position: relative;
    cursor: pointer;
    border: solid;

}

.p-item-name {
    display: none;
    align-items: center;
    justify-content: center;
    position: relative;
    color: white;
    width: 100%;
}

.largemediadiv:hover .p-single-image-search {
    display: flex;
}

.largemediadiv:hover .p-image-delete {
    display: flex;
}

.largemediadiv:hover .p-item-name {
    display: flex;
}

.smallmediadiv1:hover .p-image-delete {
    display: flex;
}

.p-upgradebuttonsmall {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 200;
    color: white;
    letter-spacing: 1px;
    background: url("../assets/img/IconsAndButtonTemplates/ButtonBlueWithWhiteTextSmall.svg") no-repeat center center;
    background-size: cover;
    cursor: pointer;
}

.p-upgradebutton {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 200;
    color: white;
    letter-spacing: 1px;
    background: url("../assets/img/IconsAndButtonTemplates/ButtonBlueWithWhiteText.svg") no-repeat center center;
    background-size: cover;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {

    body,
    html {
        overflow-x: hidden;
        overflow-y: auto;
    }

}