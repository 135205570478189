/************** Header ********************/
header {
    margin: 0px;
    padding: 0px;
    position: sticky;
    display: flex;
    top: 0;
    z-index: 10;
}

/*
header {
    margin: 0px;
    padding: 0px;
    position: fixed;
    width: 100%;
    display: flex;
    top: 0;
    z-index: 10;
}
*/

.headerlogo {
    background: url("../assets/img/Logos/LogoWhite.svg") no-repeat center center;
    background-size: cover;
    margin-left: 20px;
    margin-bottom: 6px;
}


.headerbar_mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: linear-gradient(rgb(120, 120, 130), rgb(61, 63, 70));

}

.headerbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url("../assets/img/Header/HeaderBar.svg") no-repeat center center;
    background-size: cover;
}

.headerleftbox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 116px;
    min-width: 552px;
    background: url("../assets/img/Header/HeaderBarLeft.png") no-repeat center center;
    background-size: cover;
    cursor: pointer;
}

.headerrightbox {
    display: flex;
    align-items: start;
    justify-content: right;
    height: 116px;
    width: 100%;
    /*min-width: 948px;*/
    background: url("../assets/img/Header/HeaderBarRight.png") no-repeat center center;
    background-size: cover;
    z-index: 0;
}

.headerrightbox2 {
    display: flex;
    align-items: start;
    justify-content: right;
    height: 116px;
    width: 100%;
    min-width: 948px;
    background: url("../assets/img/Header/HeaderBarRight.png") no-repeat center center;
    background-size: cover;
}



.headerportfoliobutton {
    position: relative;
    top: -15px;
    background: url("../assets/img/Header/MyPortfolio.svg") no-repeat center center;
    background-size: cover;
    margin-right: 35px;
    cursor: pointer;
    margin-top: 35px;
    width: 235px;
    height: 49px;
}

.headerportfoliobuttonmob {
    position: relative;
    top: 0px;
    background: url("../assets/img/IconsAndButtonTemplates/PortfolioIcon.svg") no-repeat center center;
    background-size: cover;
    margin-right: 0px;
    cursor: pointer;
}


.headerreportsbuttonmob {
    position: relative;
    top: 0px;
    background: url("../assets/img/IconsAndButtonTemplates/Mailreadicon.svg") no-repeat center center;
    background-size: cover;
    margin-right: 0px;
    cursor: pointer;
}

.headerunreadreportsbuttonmob {
    position: relative;
    top: 0px;
    background: url("../assets/img/IconsAndButtonTemplates/MailUnreadIcon.svg") no-repeat center center;
    background-size: cover;
    margin-right: 0px;
    cursor: pointer;
}

.headerreportsbutton {
    position: relative;
    top: -15px;
    background: url("../assets/img/Header/MyReports.svg") no-repeat center center;
    background-size: cover;
    margin-right: 35px;
    cursor: pointer;
    margin-top: 38px;
    width: 222px;
    height: 45px;
}

.headerunreadreportsbutton {
    position: relative;
    top: -15px;
    background: url("../assets/img/Header/MyReportsUnread.svg") no-repeat center center;
    background-size: cover;
    margin-right: 35px;
    cursor: pointer;
    margin-top: 38px;
    width: 222px;
    height: 45px;
}

.headeraccountbutton {
    position: relative;
    top: -15px;
    background: url("../assets/img/Header/MyAccount.svg") no-repeat center center;
    background-size: cover;
    margin-right: 35px;
    cursor: pointer;
    margin-top: 35px;
    width: 190px;
    height: 48px;
}

.headeraccountbuttonmob {
    position: relative;
    top: 0x;
    background: url("../assets/img/IconsAndButtonTemplates/UserIcon.svg") no-repeat center center;
    background-size: cover;
    margin-right: 0px;
    cursor: pointer;
}

.headersigninbutton {
    position: relative;
    top: -15px;
    background: url("../assets/img/Header/SignInButton.svg") no-repeat center center;
    background-size: cover;
    margin-right: 25px;
    cursor: pointer;
}

.headerburgermenu {
    position: relative;
    top: -29px;
    background: url("../assets/img/Header/HamburgerMenuIcon.svg") no-repeat center center;
    background-size: cover;
    margin-right: 25px;
    cursor: pointer;
}

/*******Dropdown*************/
.dropdown-item {
    height: 37px;
    width: 253px;
    cursor: pointer;
}

.dropdown-item-mob {
    height: 31px;
    width: 211px;
    cursor: pointer;
}

.dropdown-item-hover {
    border-radius: 7px;
    margin: 1px;
    background-color: transparent;
}

#idacc:hover {
    background-color: #666;
}

#idhow:hover {
    background-color: #666;
}

#idabout:hover {
    background-color: #666;
}

#idhelp:hover {
    background-color: #666;
}

#idnews:hover {
    background-color: #666;
}

#idcontact:hover {
    background-color: #666;
}

#idpolicy:hover {
    background-color: #666;
}

#idterms:hover {
    background-color: #666;
}

#idsettings:hover {
    background-color: #666;
}

#idsignout:hover {
    background-color: #666;
}

.dropdown-acc {
    background: url("../assets/img/Header/Menu/1700/AccountAndPlan.svg") no-repeat center center;
}

/*     border: 1px solid yellow;*/
.dropdown-how {
    background: url("../assets/img/Header/Menu/1700/HowItWorks.svg") no-repeat center center;
}

.dropdown-about {
    background: url("../assets/img/Header/Menu/1700/About.svg") no-repeat center center;
}

.dropdown-help {
    background: url("../assets/img/Header/Menu/1700/Help.svg") no-repeat center center;
}

.dropdown-news {
    background: url("../assets/img/Header/Menu/1700/News.svg") no-repeat center center;
}

.dropdown-contact {
    background: url("../assets/img/Header/Menu/1700/Contact.svg") no-repeat center center;
}

.dropdown-policy {
    background: url("../assets/img/Header/Menu/1700/PrivacyPolicy.svg") no-repeat center center;
}

.dropdown-settings {
    background: url("../assets/img/Header/Menu/1700/Settings.svg") no-repeat center center;
}

.dropdown-signout {
    background: url("../assets/img/Header/Menu/1700/SignOut.svg") no-repeat center center;
}

.dropdown-terms {
    background: url("../assets/img/Header/Menu/1700/TermsOfService.svg") no-repeat center center;
}


.dropdown-how-mob {
    background: url("../assets/img/Header/Menu/Mob/HowItWorks.svg") no-repeat center center;
}

.dropdown-about-mob {
    background: url("../assets/img/Header/Menu/Mob/About.svg") no-repeat center center;
}

.dropdown-help-mob {
    background: url("../assets/img/Header/Menu/Mob/Help.svg") no-repeat center center;
}

.dropdown-news-mob {
    background: url("../assets/img/Header/Menu/Mob/News.svg") no-repeat center center;
}

.dropdown-contact-mob {
    background: url("../assets/img/Header/Menu/Mob/Contact.svg") no-repeat center center;
}

.dropdown-policy-mob {
    background: url("../assets/img/Header/Menu/Mob/PrivacyPolicy.svg") no-repeat center center;
}

.dropdown-signout-mob {
    background: url("../assets/img/Header/Menu/Mob/SignOut.svg") no-repeat center center;
}

.dropdown-terms-mob {
    background: url("../assets/img/Header/Menu/Mob/TermsOfService.svg") no-repeat center center;
}



.dropdown-2empty {
    height: 44px;
    width: 253px;
}

.dropdown-2empty-mob {
    height: 25px;
    width: 211px;
}

.dropdown-empty {
    height: 22px;
    width: 253px;
}

.dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 116px;
}

.dropdown-content-mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 398px;
    width: 217px;
    position: absolute;
    left: -180px;
    top: 63px;
    background: url("../assets/img/Header/Menu/Mob/MenuBox.svg") no-repeat center center;
    z-index: 350;
}

.dropdown-content {
    z-index: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 478px;
    width: 261px;
    position: absolute;
    left: -200px;
    top: 63px;
    background: url("../assets/img/Header/Menu/1700/MenuBox.svg") no-repeat center center;
}

.dropdown-item-opacity {
    opacity: 0.2;
    cursor: 'default';
}

/*
.dropdown:hover .dropdown-content {
    display: flex;
}
*/
/*******end of Dropdown*************/
/************** End of Header *************/
/************** HomePage1 *************/
.homepage1text {
    margin-top: 170px;
    padding-left: 70px;
}

.homepage1left {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 780px;
    width: 40vw;
    height: 100vh;
}

.homepage1right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 60vw;
    height: 100%;
    z-index: 1;

}

.homepage1right .homepage1rightbg {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 40vw;
    right: 0;
    background: url("../assets/img/HomePage1/HomepageTextBox.svg") no-repeat center center;
    background-size: cover;
    opacity: .9;
    width: 60vw;
    height: 100%;
}

.protectingpourart {
    display: flex;
    justify-content: right;
    align-items: flex-start;
    position: relative;
    top: -7vh;
    width: 620px;
    height: 300px;
}

.homepage1buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 140px;

}


.homepage1imagerec {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.5px;
}


.divider1 {
    height: 30px;
}

/************** End of HomePage1r *************/


@media only screen and (max-width: 1370px) {
    .headeraccountbutton {
        position: relative;
        top: -15px;
        background: url("../assets/img/IconsAndButtonTemplates/UserIcon.svg") no-repeat center center;
        background-size: cover;
        margin-right: 15px;
        cursor: pointer;
        margin-top: 35px;
        width: 40px;
        height: 40px;
    }

    .headerunreadreportsbutton {
        position: relative;
        top: -15px;
        background: url("../assets/img/IconsAndButtonTemplates/MailUnreadIcon.svg") no-repeat center center;
        background-size: cover;
        margin-right: 20px;
        cursor: pointer;
        margin-top: 38px;
        width: 65px;
        height: 40px;
    }

    .headerreportsbutton {
        position: relative;
        top: -15px;
        background: url("../assets/img/IconsAndButtonTemplates/Mailreadicon.svg") no-repeat center center;
        background-size: cover;
        margin-right: 20px;
        cursor: pointer;
        margin-top: 38px;
        width: 65px;
        height: 40px;
    }

    .headerportfoliobutton {
        position: relative;
        top: -15px;
        background: url("../assets/img/IconsAndButtonTemplates/PortfolioIcon.svg") no-repeat center center;
        background-size: cover;
        margin-right: 15px;
        cursor: pointer;
        margin-top: 35px;
        width: 50px;
        height: 45px;
    }

}

@media only screen and (max-width: 1200px) {
    .dropdown-content {
        left: -230px;
        top: 63px;
    }

    .divider1 {
        height: 30px;
    }

    .protectingpourart {
        display: flex;
        justify-content: right;
        align-items: flex-start;
        position: relative;
        top: 10vh;
        width: 620px;
        height: 300px;
    }
}


@media only screen and (max-width: 600px) {
    .divider1 {
        height: 30px;
    }

    .protectingpourart {
        display: flex;
        justify-content: right;
        align-items: flex-start;
        position: relative;
        top: 10vh;
        width: 620px;
        height: 300px;
    }
}