.sm-mainbox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url("../assets/img/SignupAndLoginPages/DialogueBox1.svg") no-repeat center center;
    background-size: cover;
}

.sm-buttonbox {
    display: flex;
    align-items: center;
    justify-content: center;
}