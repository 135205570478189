/************ Login ******************/
.loginbox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url("../assets/img/SignupAndLoginPages/DialogueBox1.svg") no-repeat center center;
    background-size: cover;
    font-size: 12px;
    /*border: 1px solid yellow;*/
}

.loginbox-mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    background-color: #494b53;
    /*border: 1px solid yellow;*/
}

.login-title {
    text-align: left;
    height: 145px;
    width: 1100px;
}

.login-inputtitles {
    margin-left: 20px;
    margin-bottom: 5px;
}

.loginfont {
    font-size: 17px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;
}

.login-emaildiv {
    display: flex;
    align-items: left;
    justify-content: end;
    flex-direction: column;
    height: 84px;
    width: 645px;

}

.login-passworddiv {
    display: flex;
    align-items: left;
    justify-content: start;
    flex-direction: column;
    height: 70px;
    width: 645px;


}

.login-forgotpass {
    display: flex;
    align-items: start;
    justify-content: space-between;
    height: 80px;
    width: 500px;

}

.login-forgotpasslink {
    cursor: pointer;
    text-decoration: underline;
}

.login-buttondiv {
    /*height: 65px;
    width: 450px;*/
    margin-bottom: 20px;
    background: url("../assets/img/SignupAndLoginPages/SignInButton.svg") no-repeat center center;
    cursor: pointer;
}


.login-bigtitle {
    font-size: 40px;
}

.login-keepme1 {
    display: flex;
    margin-top: 5px;
}

.paymentinfobuttons {
    width: 100px;
    height: 40px;
    border-radius: 25px;
    border: 0px;
    cursor: pointer;
}

.paymentinfobuttons_blue {
    background-color: #698ff9;
}

.paymentinfobuttons_gray {
    background-color: #a6abb9;
}


#button-text-back,
#button-text {
    color: white;
}

.paymentinfobuttonsdiv {
    margin-top: 25px;
    height: 45px;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-around;

}

#loginpassword,
#loginemail {
    height: 30px;
    width: 640px;
    font-size: 20px;
    text-align: center;
    border-radius: 7px;
}

.login-errormessage {
    color: red;
}

/************ End of Login ******************/

/************ CreateTrial ******************/
.createtrial-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 560px;
    width: 1240px;
    background: url("../assets/img/SignupAndLoginPages/CreateTrial.svg") no-repeat center center;
    background-size: cover;
    font-size: 12px;
}

.createtrial-box-mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #494b53;
    font-size: 12px;
}


.createtrial-title {
    text-align: left;
    height: 110px;
    width: 1100px;
}

.createtrial-inputtitles {
    margin-left: 20px;
    margin-bottom: 5px;
}

.createtrial-input {
    display: flex;
    align-items: left;
    justify-content: end;
    flex-direction: column;
    height: 70px;
    width: 645px;

}

.createtrial-bigtitle {
    font-size: 40px;
}

#createtrial-password,
#createtrial-email,
#createtrial-password2 {
    text-align: center;
    border-radius: 7px;
    outline: none;

}

#contactus-name,
#contactus-email,
#contactus-subject,
#contactus-text {
    text-align: left;
    border-radius: 7px;
    outline: none;

}



/************ End of CreateTrial ******************/
/****************Create account Common****************/
.ca-mainbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: url("../assets/img/SignupAndLoginPages/CreateAccount.svg") no-repeat center center;
    background-size: cover;
}

.ca-top {
    display: flex;
    align-items: end;
    justify-content: center;


}

.ca-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    /*border: 1px solid yellow; */
}

.ca-top-right1 {
    display: block;
    background: url("../assets/img/SignupAndLoginPages/IndividualToggle.svg") no-repeat center center;
    background-size: cover;
    cursor: pointer;
}

.ca-top-right2 {
    display: none;
    background: url("../assets/img/SignupAndLoginPages/BusinessToggle.svg") no-repeat center center;
    background-size: cover;
    cursor: pointer;
}

.ca-top-left {
    display: flex;
    align-items: center;
    justify-content: center;
}

/****************Create I account****************/
/****************Create B account****************/

/***************ConfirmEmail************************/
/****************Forgot Password***********************/
.forgotpassword-emaildiv {
    display: flex;
    align-items: left;
    justify-content: end;
    flex-direction: column;
    height: 154px;
    width: 645px;
}

/****************Forgot Password Message***********************/
/****************Reset Password***********************/
/****************Reset Password Message***********************/
/***************************************/
/***************************************/
/***************************************/
.paymentelement {
    color: #fff;
    background-color: #a6abb9;
    border-radius: 12px;
    padding: 10px;
}



@media only screen and (max-width: 1700px) {

    /*
    .login-buttondiv{
        height: 46px;
        width: 321px;
        background: url("../assets/img/SignupAndLoginPages/1700/SignInButton.svg")  no-repeat center center;       
    }
    .loginbox{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction:column;
        height: 400px;
        width: 886px;    
        background: url("../assets/img/SignupAndLoginPages/1700/DialogueBox1.svg")  no-repeat center center;       
        font-size: 12px;
    }

    .login-title{
        text-align: left;
            height: 103px;
            width:  786px;
        }
        .login-inputtitles 
        {
            margin-left: 20px;
            margin-bottom: 5px;
        }
        .loginfont{
            font-size: 12px;
            font-weight: 400;
            font-family: 'Oswald', sans-serif;
            letter-spacing: 1px;        
        }
        .login-emaildiv{
            display: flex;
            align-items: left;
            justify-content:end;
            flex-direction:column;
            height: 60px;
            width:  460px;

        }
        .login-passworddiv{
            display: flex;
            align-items: left;
            justify-content:start;
            flex-direction:column;
            height: 50px;
            width:  460px;

            
        }
        .login-forgotpass{
            display: flex;
            align-items:start;
            justify-content: space-between;
            height: 51px;
            width:  357px;

            
        }
        .login-forgotpasslink{
            cursor: pointer;
            text-decoration: underline;
        }
        .login-buttondiv{
            height: 46px;
            width: 321px;
            margin-bottom: 10px;
            background: url("../assets/img/SignupAndLoginPages/1700/SignInButton.svg")  no-repeat center center;       
            cursor: pointer;
        }
        .login-keepme2{
            height: 17px;
            width: 17px; 
            border-radius: 4px;
            background-color: white;
            margin-right: 10px;
            cursor: pointer;    
        }
        
        .login-bigtitle{
            font-size: 30px;  
        }
        .login-keepme1{
            display: flex;
        }
        
        #loginpassword, #loginemail{
            height: 22px; 
            width: 457px;
            font-size: 20px;
            text-align: center;
            border-radius: 7px;
         }
*/
    /************ CreateTrial ******************/
    /*
    .createtrial-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 400px;
        width: 885px;
        background: url("../assets/img/SignupAndLoginPages/1700/DialogueBox1.svg") no-repeat center center;
        font-size: 12px;
    }

    .createtrial-title {
        text-align: left;
        height: 79px;
        width: 786px;
    }

    .createtrial-inputtitles {
        margin-left: 14px;
        margin-bottom: 5px;
    }

    .createtrial-input {
        display: flex;
        align-items: left;
        justify-content: end;
        flex-direction: column;
        height: 50px;
        width: 461px;

    }

    .createtrial-bigtitle {
        font-size: 30px;
    }

    #createtrial-password,
    #createtrial-email,
    #createtrial-password2 {
        height: 21px;
        width: 457px;
        font-size: 13px;
        text-align: center;
        border-radius: 7px;

    }
*/
    /************ End of CreateTrial ******************/
    /****FG pass*********/
    .forgotpassword-emaildiv {
        display: flex;
        align-items: left;
        justify-content: end;
        flex-direction: column;
        height: 154px;
        width: 645px;
    }


}