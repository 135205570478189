body,
html {
  padding: 0;
  border: 0;
  margin: 0;

  font-family: 'Oswald', sans-serif;

  /*overflow: hidden; */

  /* width */
  ::-webkit-scrollbar {
    width: 15px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #698ff9;
    border-radius: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }

  /*overflow-y: visible;
  overflow-x: visible;*/
}

body {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("./assets/img/Bg/bg_v1_1920x1080.png") no-repeat center center;
  background-size: cover;
}


@media only screen and (max-width: 600px) {
  /*
  body {
    background: #a6abb9;
  }
*/

  body,
  html {
    overflow-x: hidden;
    overflow-y: auto;
  }

}

/*
    background: #5b5e68;

  ::-webkit-scrollbar {
    width: 10px;
  }

  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #5b5e68;
    border-radius: 10px;
  }

  
  ::-webkit-scrollbar-thumb {
    background: #567ffa;
    border-radius: 10px;
  }

  
  ::-webkit-scrollbar-thumb:hover {
    background: #1941b9;
  }
  
}
*/
/*
@media only screen and (max-width: 600px) {

 body,
  html {
    overflow-x: hidden;
    overflow-y: auto;
    background: #5b5e68;
  }

  
  ::-webkit-scrollbar {
    width: 10px;
  }

  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #5b5e68;
    border-radius: 10px;
  }

  
  ::-webkit-scrollbar-thumb {
    background: #567ffa;
    border-radius: 10px;
  }

 
  ::-webkit-scrollbar-thumb:hover {
    background: #1941b9;
  }
}
*/