/************** Common styles ************/
.maincontainer {
    display: flex;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
}

.maincontainer2 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
}

.thankyoumessage {
    color: rgb(115, 228, 115);
    margin-bottom: 20px;
    height: 20px;
    font-size: 20px;
}

.colore6e6e6 {
    color: #e6e6e6;
}

.colord9d9d9 {
    color: #d9d9d9;
}

.colorcccccc {
    color: #cccccc;
}

.color698ff9 {
    color: #698ff9;
}

.bigfont {
    font-size: 50px;
    font-weight: 500;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;
}

.midfont {
    font-size: 28px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;
}

.smallfont {
    font-size: 22px;
    font-weight: 300;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;
}

/***********Simple containers******/
.container-row-bottom {
    display: flex;
    align-items: end;
    justify-content: flex-start;
    /*border: 1px solid yellow;*/
}

.container-row-up {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    /*border: 1px solid yellow;*/
}

.container-row-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-row-between {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.container-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.container-l-l-column {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

}

/***********Icons containers******/
.icon-unlock {
    background: url("../assets/img/IconsAndButtonTemplates/UnlockIcon.svg") no-repeat center center;
    background-size: cover;
}

.icon-mailunread {
    background: url("../assets/img/IconsAndButtonTemplates/MailUnreadIcon.svg") no-repeat center center;
    background-size: cover;
}

.icon-clock {
    background: url("../assets/img/IconsAndButtonTemplates/ClockIcon.svg") no-repeat center center;
    background-size: cover;
}

.icon-portfolio {
    background: url("../assets/img/IconsAndButtonTemplates/PortfolioIcon.svg") no-repeat center center;
    background-size: cover;
}

.icon-group {
    background: url("../assets/img/IconsAndButtonTemplates/GroupIcon.svg") no-repeat center center;
    background-size: cover;
}

.icon-user {
    background: url("../assets/img/IconsAndButtonTemplates/UserIcon.svg") no-repeat center center;
    background-size: cover;
}

.icon-lock {
    background: url("../assets/img/IconsAndButtonTemplates/LockIcon.svg") no-repeat center center;
    background-size: cover;
}

/*********Buttons******************/
.emptybuttonspan {
    font-weight: 600;
    font-family: 'Oswald', sans-serif;
    color: white;
    letter-spacing: 2px;
}

.emptybutton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../assets/img/SignupAndLoginPages/ButtonTemplateBlueWithWhiteText.svg") no-repeat center center;
    cursor: pointer;
    background-size: cover;
}

.emptybuttongray {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../assets/img/SignupAndLoginPages/ButtonTemplateGreyWithDarkText.svg") no-repeat center center;
    cursor: pointer;
    background-size: cover;
}

.smallbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../assets/img/SignupAndLoginPages/ButtonTemplateGreyWithDarkText.svg") no-repeat center center;
    cursor: pointer;
    background-size: cover;
}

.artbluebutton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../assets/img/IconsAndButtonTemplates/ButtonTemplateArt.svg") no-repeat center center;
    cursor: pointer;
}

.continuebutton {
    margin: 10px;
    background: url("../assets/img/SignupAndLoginPages/ContinueButton.svg") no-repeat center center;
    background-size: cover;
    cursor: pointer;
}

/********* End of Buttons******************/
.mainbox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url("../assets/img/SignupAndLoginPages/DialogueBox1.svg") no-repeat center center;
    background-size: cover;
    font-size: 12px;
}

.emptybluebutton {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}

@media only screen and (max-width: 1700px) {

    .emptybutton {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
        background: url("../assets/img/SignupAndLoginPages/1700/ButtonTemplateBlueWithWhiteText.svg") no-repeat center center;
        background-size: cover;
        cursor: pointer;
    }

    .continuebutton_no {
        height: 46px;
        width: 321px;
        margin: 10px;
        background: url("../assets/img/SignupAndLoginPages/1700/ContinueButton.svg") no-repeat center center;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1000px) {
    .emptybutton {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
        background: url("../assets/img/SignupAndLoginPages/1700/ButtonTemplateBlueWithWhiteText.svg") no-repeat center center;
        cursor: pointer;
    }

    .continuebutton_no {
        height: 46px;
        width: 321px;
        margin: 10px;
        background: url("../assets/img/SignupAndLoginPages/1700/ContinueButton.svg") no-repeat center center;
        cursor: pointer;
    }
}

/*******Consent**********/
.consent-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #47484d;
    opacity: 0.90;
    transition: opacity 2s ease;
    z-index: 100;
}

.hideconsent {
    opacity: 0;
    z-index: 0;
}

/*******END of Consent**********/
.checkbox-box {
    height: 25px;
    width: 25px;
    border-radius: 7px;
    background-color: white;
    margin-right: 10px;
    cursor: pointer;
}

.checkbox-mark {
    opacity: 0;
    height: 25px;
    width: 25px;
    margin-right: 10px;
    border-radius: 7px;
    background-color: white;
    background: url("../assets/img/SignupAndLoginPages/check.png") no-repeat center center;
    background-size: cover;
    cursor: pointer;
}

.checkbox-outerbox {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    letter-spacing: 1px;
    /*border: 1px solid yellow;*/
}

.checkbox-block {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /*border: 1px solid yellow;*/
}

/****************************************/
.radiobutton-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    border-radius: 7px;
    background-color: white;
    margin-right: 5px;
    cursor: pointer;

}

.radiobutton-mark {
    opacity: 1;
    background-color: black;
    cursor: pointer;
}

.radiobutton-outerbox {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    letter-spacing: 1px;
    /*border: 1px solid yellow;*/
}

.radiobutton-block {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}